import React, { Component, useState, useEffect } from "react"
import { Form, Input, InputNumber, Radio, Select, Row, Col, Button } from "antd"
import Footer from "../../components/layout-components/modal/Footer"
import API from "../../api"
import { connect } from "react-redux"
import { AddCustomer, EditCustomer } from "../../actions/customerAction"
// import {CustomerDetails,clientPrimary} from './customerform'
const { Option } = Select
export const CustomerDetails = [
  {
    name: "name",
    lable: "Customer Name",
    rules: [
      {
        required: true,
        message: "Please Enter your customer name!",
        whitespace: true,
      },
    ],
  },
  {
    name: "street_address",
    lable: "Street Address",
    rules: [
      {
        required: false,
        message: "Please Enter your street address!",
        whitespace: true,
      },
    ],
  },
  {
    name: "zipcode",
    lable: "Zip Code",
    // type:'number',
    rules: [
      {
        required: false,
        message: "Please Enter your zipcode!",
      },
      // {
      //   type: 'number',
      //   message: 'Zipcode should be Number only!'
      // },
    ],
  },
  {
    name: "city",
    lable: "City",
    //disabled: true,
    rules: [
      {
        required: false,
        message: "Please Enter your city!",
        whitespace: true,
      },
    ],
  },
  {
    name: "state",
    lable: "State",
    //disabled: true,
    rules: [
      {
        required: false,
        message: "Please Enter your state!",
        whitespace: true,
      },
    ],
  },
]
const billingAddress = [
  {
    name: "billing_street_address",
    lable: "Street Address",
    rules: [
      {
        required: false,
        message: "Please Enter your street address!",
        whitespace: true,
      },
    ],
  },
  {
    name: "billing_zipcode",
    lable: "Zip Code",
    // type:'number',
    rules: [
      {
        required: false,
        message: "Please Enter your zipcode!",
      },
      //  {
      //   type: 'number',
      //   message: 'Zipcode should be Number only!'
      // },
    ],
  },
  {
    name: "billing_city",
    //disabled: true,
    lable: "City",
    rules: [
      {
        required: false,
        message: "Please Enter your city!",
        whitespace: true,
      },
    ],
  },
  {
    name: "billing_state",
    lable: "State",
    //disabled: true,
    rules: [
      {
        required: false,
        message: "Please Enter your state!",
        whitespace: true,
      },
    ],
  },
]
const billingContact = [
  {
    name: "billing_first_name",
    lable: "First Name",
    rules: [
      {
        required: false,
        message: "Please Enter billing first name!",
        whitespace: true,
      },
    ],
  },
  {
    name: "billing_last_name",
    lable: "Last Name",
    rules: [
      {
        required: false,
        message: "Please Enter billing last name!",
        whitespace: true,
      },
    ],
  },
  {
    name: "billing_email",
    lable: "Email Address",
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail!",
      },
      {
        required: false,
        message: "Please Enter your E-mail!",
      },
    ],
  },

  {
    name: "billing_phone",
    lable: "Phone No.",
    type: "number",
    rules: [
      {
        required: false,
        message: "Please Enter billing phone!",
      },
      {
        type: "number",
        message: "phone should be Number only!",
      },
    ],
  },
  {
    name: "billing_phone_other",
    lable: "Other Phone No.",
    type: "number",
    rules: [
      {
        required: false,
        message: "Please Enter other billing phone!",
      },
      {
        type: "number",
        message: "other phone should be Number only!",
      },
    ],
  },
]
const customerContact = [
  {
    name: "first_name",
    lable: "First Name",
    rules: [
      {
        required: false,
        message: "Please Enter your first name!",
        whitespace: true,
      },
    ],
  },
  {
    name: "last_name",
    lable: "Last Name",
    rules: [
      {
        required: false,
        message: "Please Enter your last name!",
        whitespace: true,
      },
    ],
  },
  {
    name: "email",
    lable: "Email Address",
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail!",
      },
      {
        required: false,
        message: "Please Enter your E-mail!",
      },
    ],
  },
  {
    name: "phone",
    lable: "Phone No.",
    type: "number",
    rules: [
      {
        required: false,
        message: "Please Enter your phone!",
      },
      {
        type: "number",
        message: "phone should be Number only!",
      },
    ],
  },
  {
    name: "phone_other",
    lable: "Other Phone No.",
    type: "number",
    rules: [
      {
        required: false,
        message: "Please Enter your other phone!",
      },
      {
        type: "number",
        message: "other phone should be Number only!",
      },
    ],
  },
]

// const lastTicketNo=  {
//   name: 'last_ticket_number',
//   lable: 'LAST TICKET NUMBER',
//   rules: [
//     {type:'number',
//     message:'last ticket number should be number'
//     },
//   ],
// }

const RegistrationForm = props => {
  const [form] = Form.useForm()
  const [formLayout, setFormLayout] = useState("horizontal")
  const [customerDetails, setCustomerDetails] = useState(CustomerDetails)
  const [billAddress, setBillAddress] = useState(true)
  const [_billingContact, setBillingContact] = useState(true)
  // const [billToCont,setBillToCont]=useState(true)
  const [visible, setVisible] = useState(false)
  const [btnDisable, setBtnDisable] = useState(false)
  const { getCustomerDetails, getZipData } = API

  useEffect(() => {
    if (
      props.title === "Edit Customer" &&
      props.clients_cust_id !== undefined
    ) {
      getCustomerDetails(props.clients_cust_id).then(res => {
        const _data = res.data.data
        form.setFieldsValue(_data)
        const contact = {
          ..._data,
          zipcode: parseInt(_data.zipcode),
          phone: parseInt(_data.phone),
          phone_number: parseInt(_data.phone_number),
          phone_other: _data.phone_other ? parseInt(_data.phone_other) : "",
          billing_phone: parseInt(_data.billing_phone),
          billing_phone_other: _data.billing_phone_other
            ? parseInt(_data.billing_phone_other)
            : "",
          billing_zipcode: _data.billing_zipcode
            ? parseInt(_data.billing_zipcode)
            : "",
        }
        form.setFieldsValue(contact)
      })
    }
  }, [props.clients_cust_id || props.title])
  useEffect(() => {
    if (visible === false) {
      setBtnDisable(false)
      setBillingContact(true)
      setBillAddress(true)
    }
  }, [visible])

  const ChangeBillAddress = e => {
    setBillAddress(e.target.value)
  }
  const ChangeContact = e => {
    setBillingContact(e.target.value)
  }
  const getZip = e => {
    const id = e.target.id
    if (id === "register_zipcode" || id === "register_billing_zipcode") {
      getZipData(e.target.value).then(res => {
        const data = res.data.data
        data &&
          data.map(item => {
            if (id === "register_zipcode") {
              form.setFieldsValue({ state: item.state, city: item.city })
            } else {
              form.setFieldsValue({
                billing_state: item.state,
                billing_city: item.city,
              })
            }
          })
      })
    }
  }
  const resetZip = e => {
    const id = e.target.id
    const value = e.target.value
    if (id === "register_zipcode") {
      form.setFieldsValue({ state: "", city: "" })
    }
    if (id === "register_billing_zipcode") {
      form.setFieldsValue({ billing_state: "", billing_city: "" })
    }
  }

  const onFinish = values => {
    setBtnDisable(true)

    values = {
      ...values,
      currency_id: "USD",
      zipcode: values.zipcode.toString(),
      logo: "",
      phone: values.phone.toString(),
      phone_other: values.phone_other ? values.phone_other.toString() : "",
      billing_phone: values.billing_phone
        ? values.billing_phone.toString()
        : "",
      billing_phone_other: values.billing_phone_other
        ? values.billing_phone_other.toString()
        : "",
    }
    let value = { ...values }

    if (billAddress) {
      values = {
        ...values,
        billing_address: value.street_address,
        billing_zipcode: value.zipcode,
        billing_city: value.city,
        billing_state: value.state,
      }
    }
    if (_billingContact) {
      values = {
        ...values,
        billing_first_name: value.first_name,
        billing_last_name: value.last_name,
        billing_email: value.email,
        billing_phone: value.phone,
        billing_phone_other: value.other_phone,
      }
    }
    const callback = val => {
      if (val === true) {
        form.resetFields()
        if (props.equipmentcheckin) {
          props.updateCustomerApi()
        }
        setBtnDisable(false)
        setVisible(false)
      } else {
        setBtnDisable(false)
      }
    }
    if (props.title === "Edit Customer") {
      const data = {
        ...values,
        clients_cust_id: props.clients_cust_id,
      }
      props.EditCustomer(data, callback)
    } else {
      props.AddCustomer(values, callback)
    }
  }
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout)
  }
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: { span: 8 },
          wrapperCol: { span: 8 },
        }
      : null
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        virtual={false}
        style={{
          width: 70,
        }}
      >
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  )

  return (
    <Footer
      equipmentcheckin={props.equipmentcheckin}
      setVisible={e => setVisible(e)}
      visible={visible}
      Button={props.Button}
      title={props.title}
      custom={props.custom}
      className={props.className}
      submit={
        <Form.Item>
          <Button
            disabled={btnDisable}
            type="primary"
            htmlType="submit"
            form={
              props.clients_cust_id
                ? `editCustomer${props.clients_cust_id.toString()}`
                : "newCustomer"
            }
            style={{ fontWeight: "600" }}
          >
            {props.title === "Edit Customer" ? "SAVE" : "ADD"}
          </Button>
        </Form.Item>
      }
    >
      <Form
        form={form}
        onChange={e => resetZip(e)}
        onBlur={e => getZip(e)}
        name="register"
        id={
          props.clients_cust_id
            ? `editCustomer${props.clients_cust_id.toString()}`
            : "newCustomer"
        }
        onFinish={onFinish}
        initialValues={{
          residence: ["zhejiang", "hangzhou", "xihu"],
          prefix: "86",
        }}
        scrollToFirstError
      >
        <Row gutter={24}>
          {customerDetails &&
            customerDetails.map(form => (
              <Col span={8}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={form.name}
                  label={<span>{form.lable}</span>}
                  initialValue={form.initialValue ? form.initialValue : ""}
                  // initialValue={form.initialValue?form.initialValue:''}
                  rules={form.rules}
                >
                  {form.type === "number" ? (
                    <InputNumber style={{ width: "100%" }} />
                  ) : (
                    <Input disabled={form.disabled} />
                  )}
                </Form.Item>
              </Col>
            ))}

          {/* {props.title !== 'Edit Customer'&&lastTicketNo &&
              <Col span={8}>
                <Form.Item
                  labelCol={{span: 24}}
                  wrapperCol={{span: 24}}
                  name={lastTicketNo.name}
                  label={
                    <span>
                      {lastTicketNo.lable}
                    </span>
                  }
                  initialValue={lastTicketNo.initialValue ? lastTicketNo.initialValue : ''}
                  // initialValue={lastTicketNo.initialValue?lastTicketNo.initialValue:''}
                  rules={lastTicketNo.rules}
                >
                   <InputNumber style={{width:'100%'}} />
                </Form.Item>
              </Col>
            } */}
        </Row>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            <b style={{ fontStyle: "italic" }}>ADDRESS IS</b>
          </span>
          <Radio.Group
            id="billing"
            onChange={e => ChangeBillAddress(e)}
            value={billAddress}
          >
            <span style={{ marginRight: 20 }}>
              {" "}
              <b style={{ fontStyle: "italic" }}>SAME AS STREET ADDRESS </b>
            </span>

            <Radio value={true} style={{ fontStyle: "italic" }}>
              YES
            </Radio>
            <Radio value={false} style={{ fontStyle: "italic" }}>
              NO
            </Radio>
          </Radio.Group>
        </div>
        <br />
        {!billAddress && (
          <Row gutter={24}>
            {billingAddress &&
              billingAddress.map(form => (
                <Col span={8}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={form.name}
                    label={<span>{form.lable}</span>}
                    initialValue={form.initialValue ? form.initialValue : ""}
                    // initialValue={form.initialValue?form.initialValue:''}
                    rules={form.rules}
                  >
                    {form.type === "number" ? (
                      <InputNumber style={{ width: "100%" }} />
                    ) : (
                      <Input disabled={form.disabled} />
                    )}
                  </Form.Item>
                </Col>
              ))}
          </Row>
        )}

        <Row gutter={24}>
          {customerContact &&
            customerContact.map(form => (
              <Col span={8}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={form.name}
                  label={<span>{form.lable}</span>}
                  initialValue={form.initialValue ? form.initialValue : ""}
                  // initialValue={form.initialValue?form.initialValue:''}
                  rules={form.rules}
                >
                  {form.type === "number" ? (
                    <InputNumber style={{ width: "100%" }} />
                  ) : (
                    <Input disabled={form.disabled} />
                  )}
                </Form.Item>
              </Col>
            ))}
        </Row>

        <Radio.Group onChange={e => ChangeContact(e)} value={_billingContact}>
          <span style={{ marginRight: 82 }}>
            {" "}
            <b style={{ fontStyle: "italic" }}>BILL TO CONTACT</b>
          </span>

          <Radio id="contact" style={{ fontStyle: "italic" }} value={true}>
            YES
          </Radio>
          <Radio id="contact" style={{ fontStyle: "italic" }} value={false}>
            NO
          </Radio>
        </Radio.Group>
        <br />
        <br />
        {!_billingContact && (
          <Row gutter={24}>
            {billingContact &&
              billingContact.map(form => (
                <Col span={8}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={form.name}
                    label={<span>{form.lable}</span>}
                    initialValue={form.initialValue ? form.initialValue : ""}
                    // initialValue={form.initialValue?form.initialValue:''}
                    rules={form.rules}
                  >
                    {form.type === "number" ? (
                      <InputNumber style={{ width: "100%" }} />
                    ) : (
                      <Input disabled={form.disabled} />
                    )}
                  </Form.Item>
                </Col>
              ))}
          </Row>
        )}
      </Form>
    </Footer>
  )
}

export class Register extends Component {
  render() {
    return <RegistrationForm {...this.props} />
  }
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  user_status: state.user.user_status,
})

export default connect(mapStateToProps, { AddCustomer, EditCustomer })(Register)
