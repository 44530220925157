import React,{useEffect,useState} from 'react';
import {Modal,Form,Col,Row,Select,Input,message,InputNumber,DatePicker,Button} from 'antd';
import { PoForm } from './comp/poForm';
import { assendingSort} from '../../utils/helper';
import moment from 'moment';
import {
  PlusOutlined,
} from '@ant-design/icons';
import {IndustryType} from '../../utils/helper'
import API from '../../api';
const { Option } = Select;
const AddPo=(props)=>{
    const [form] = Form.useForm();
    const {
      getPOServiceList,
      addPurchaseOrder,
      fetchFoodList
    } = API;
    const {visible,onCancelTicket,setVisible,confirmLoading,updatePo,disabled,client_cust_id}=props;
    const [btnDisable,setBtnDisable]=useState(false)
    const [issueDate, setIssueDate] = useState(moment());
    const [expireDate, setExpireDate] = useState(moment());
    const [poForm, setPoForm] = useState(PoForm());
    const [state, setState] = useState ({
        loading: false,
        visible: false,
      });
      const showModal = () => {
        setState ({
          ...state,
          visible: true,
        });
        setVisible (true);
      };
      const callPOServiceListApi = () => {
        const callback=(resp)=>{
          const serviceData=resp.data.data;
            let serviceGroup=[]
            serviceData&&serviceData.forEach(function(item){
              let _serviceGroup = serviceGroup.filter(x => x.value == item.service_group_id);
              if(_serviceGroup.length <= 0){
                serviceGroup.push({displayValue:item['service_group_name.param_description'],value:item.service_group_id.toString()});
              }})
            const _poform=[...poForm];
            _poform.map((item)=>{
             if(item.name==="services"){
               item.value=assendingSort(serviceGroup)
              }
            })
            setPoForm(_poform)
        }
      if(IndustryType()!=="Food_Grade"){
        getPOServiceList()
          .then((resp) => {
            callback(resp)
          })
          .catch((er) => {
            console.log('error in callPOServiceListApi API', er);
          });
      }else{
        fetchFoodList()
        .then((resp) => {
          callback(resp)
        })
        .catch((er) => {
          console.log('error in callPOServiceListApi API', er);
        });
      }
      };
      useEffect (
        () => {
          form.resetFields();
          setState ({
            ...state,
            visible: visible,
          });
          form.setFieldsValue({'expiry_date':moment(),"issue_date":moment(),services:[],"client_cust_id":client_cust_id})
          callPOServiceListApi()
          const _poform=[...poForm];
          const filterdData = props?.data.filter((item)=>item.name==="client_cust_id")
          _poform.map((item)=>{
             if(item.name==="client_cust_id"){
               item.value=filterdData[0].value
             }
           })
            setPoForm(_poform)
        },
        
        [visible]
      );
      const handleOk = () => {
        setState ({...state, loading: true});
    
        setTimeout (() => {
          setState ({...state, loading: false, visible: false});
        }, 3000);
      };    
      const handleCancel = () => {
        setVisible (false);
        setState ({...state, visible: false});
      };
      const dropDownSelect=()=>{

      }
      const onFinish=(item)=>{
            let params = {...item,
              client_cust_id:parseInt(item.client_cust_id),
              issue_date: `${item.issue_date.format()}`,
              expiry_date: `${item.expiry_date.format()}`,
              coordinates: [0.0, 0.0],
            };
            addPurchaseOrder(params)
              .then((resp) => {
                if (resp.data.status) {
                  updatePo()
                  setVisible(false)
                  message.success({ content: `${resp.data.message}`, duration: 2 });
                } else {
                  message.error({ content: `${resp.data.message}`, duration: 2 });
                }
                // setServiceList(resp.data.data)
              })
              .catch((er) => {
                message.error({ content: `${er?.response?.data.message}`, duration: 3 });
              });
          
      }
return <div className="footer-modal" style={{marginBottom:20,textAlign:'end'}}>
<div  
   custom={true}
    className="wash_po_add_edit_btn"
    style={{pointerEvents:disabled&&'none',color:disabled&&'rgba(0, 0, 0, 0.25)'}}
onClick={()=>showModal()} disabled={disabled}><PlusOutlined/>  CREATE PURCHASE ORDER</div>
<Modal
centered
 maskClosable={false}
          title="CREATE PURCHASE ORDER"
          visible={visible}
          confirmLoading={confirmLoading}
          onOk={()=>handleOk()}
          onCancel={()=>handleCancel(false)}
          okText="Submit"
          footer={[
            <Form.Item>
            <Button
              disabled={btnDisable}
              type="primary"
              form={"poFormEqCheckin"}
              htmlType="submit"
            >
            Submit
            </Button>
          </Form.Item>

          ]}
        >
       <Form
        form={form}
        id="poFormEqCheckin"
        name="poFormEqCheckin"
        onFinish={onFinish}
        scrollToFirstError
      >
<Row gutter={24}>
          {poForm &&
            poForm.map((_form) => (  
            <Col span={_form.col}>
                 <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={_form.name}
                  rules={_form.rules}
                  label={<span>{_form.lable}</span>}
                  initialValue={_form.initialValue ? _form.initialValue : ''}
                  // initialValue={_form.initialValue?_form.initialValue:''}
                >
{_form.type === 'dropdown' ? (

                    <Select
                    virtual={false}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                      disabled={_form.name==="client_cust_id"}
                      allowClear={false}
                      mode={_form.name==="services"&&"multiple"}
                      placeholder={`SELECT ${_form.lable}`}
                      style={_form.style}
                      onChange={(e) => dropDownSelect(e, _form.name,_form.value)}
                    >
                      {_form.value &&
                        _form.value.map((item) => (
                          <Option value={item.value}>
                            {item.displayValue}
                          </Option>
                        ))}
                    </Select>
                    
                    
                    
                  ):_form.name==="issue_date"?
                  <DatePicker allowClear={false}  format="MM/DD/YYYY" value={issueDate} disabledDate={d=>d.isAfter(expireDate)} onChange={(e,dateString)=>setIssueDate(dateString)} />
                  :_form.name==="expiry_date"?  
                  <DatePicker allowClear={false} format="MM/DD/YYYY" value={expireDate} disabledDate={d=>d.isBefore(issueDate)} onChange={(e,dateString)=>setExpireDate(dateString)} />
                  :_form.type === 'number' ? 
                  (<InputNumber
                  disabled={form.disabled}
                  style={{ width: '100%'}}
                  min={0}
                />):
                _form.type==="textArea"? 
                <Input.TextArea
                disabled={_form.disabled}
                style={{ height: 100 }}
              />
                
                  
                  :<Input disabled={_form.disabled } />}
</Form.Item>
            </Col>))
            }
            
            </Row>
      </Form>
        </Modal>
        </div>
}

export default AddPo;