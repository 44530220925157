import React, {useState, useEffect, useRef} from 'react';
import {Input, Button} from 'antd';

const useComponentVisible = initialIsVisible => {
  const [isComponentVisible, setIsComponentVisible] = useState (
    initialIsVisible
  );
  const ref = useRef (null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains (event.target)) {
      setIsComponentVisible (false);
    }
  };

  useEffect (() => {
    document.addEventListener ('click', handleClickOutside, true);
    return () => {
      document.removeEventListener ('click', handleClickOutside, true);
    };
  });

  return {ref, isComponentVisible, setIsComponentVisible};
};

const PopOver = props => {
  const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible (
    true
  );
  useEffect (
    () => {
      setIsComponentVisible (props.rejectPop);
    },
    [props.rejectPop]
  );

  useEffect (
    () => {
      if (isComponentVisible === false) {
        props.setRejectPop (false);
      }
    },
    [isComponentVisible]
  );

  return (
    <div className="pop-over-container" ref={ref}>
      {isComponentVisible &&
        <div className="pop-over">
          <span style={{textTransform: 'uppercase'}}>
            Are You Sure You Want To Reject This Equipment For Further Process?
          </span>
          <Input.TextArea
            value={props.remark}
            onChange={e => props.setRemark (e)}
            style={{marginTop: 15}}
            placeholder="PLEASE ADD A REMARK"
          />
          <div style={{marginTop: 20}}>
            <Button
              type="primary"
              danger
              onClick={() => props.setRejectPop (false)}
            >
              No
            </Button>
            <Button
              type="primary"
              disabled={props.remark.length === 0}
              onClick={() => props.confirmReject ()}
            >
              Yes
            </Button>
          </div>

        </div>}
    </div>
  );
};
export default PopOver;
