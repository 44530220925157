export const PoForm =() => [
    {
      name: 'client_cust_id',
      col: 12,
      lable: 'Customer Name',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select Customer!',
          whitespace: true,
        },
      ],
    },
    {
        name: 'po_no',
        col: 12,
        lable: 'PO No.',
        rules: [
          {
            required: true,
            message: 'Please Enter Po Number!',
            whitespace: true,
          },
        ],
      },
      {
        name: 'po_value',
        col: 12,
        lable: 'PO Values',
        type: 'number',
        rules: [
          {
            required: true,
            message: 'Please Enter Po Value!',
          },
        ],
      },
      
      {
        name: 'services',
        col: 12,
        lable: 'Services',
        type: 'dropdown',
        value:[],
        rules: [
          {
            required: false,
            message: 'Please Select Service!',
          },
        ],
      },
      {
        name: 'issue_date',
        col: 12,
        lable: 'PO Issue Date',
        type: 'date',
        rules: [
          {
            required: true,
            message: 'Please PO Issue Date Service!',
          },
        ],
      },
      {
        name: 'expiry_date',
        col: 12,
        lable: 'PO Expiry Date',
        type: 'date',
        rules: [
          {
            required: true,
            message: 'Please PO Expiry Date Service!',
          },
        ],
      },
      {
        name: 'remark',
        col: 24,
        lable: 'Remarks',
        type: 'textArea',
        rules: [
          {
            required: false,
            whitespace: true,
          },
        ],
      },
]